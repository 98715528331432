<template>
  <v-app :dark="isDark">
    
      <nuxt class="nuxt-empty" fill-height/>
    
  </v-app>
</template>
<script>
export default {
  transition: "fade",
  computed: {
    isDark() {
      return this.$store.state.settings.isDark;
    }
  }
};
</script>
<style>
html,
body,
#__nuxt,
#__layout {
  height: 100%;
}
.nuxt-empty {
  height: 100%;
}
</style>


