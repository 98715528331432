import Vue from 'vue';

export const state = () => ({
    logs: [],
    units: {
        0: '',
        1: '',
    }
})

export const mutations = {    
    setLogs(state, logs) {
        state.logs = logs;
    },

    setUnit(state, options) {
        state.units[options.index] = options.unit
        
    }
}

export const actions = {

    async getLogs({ commit }, options) {

        let logs = await this.$axios.$post('/api/stables/' +  options.stableId + '/history/', options.data)
        commit('setLogs', logs.reverse())
        return logs;
    },


}