import { render, staticRenderFns } from "./SideMenu.vue?vue&type=template&id=0f19f2f0&scoped=true&"
import script from "./SideMenu.vue?vue&type=script&lang=js&"
export * from "./SideMenu.vue?vue&type=script&lang=js&"
import style0 from "./SideMenu.vue?vue&type=style&index=0&id=0f19f2f0&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f19f2f0",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListTile } from 'vuetify/lib/components/VList';
import { VListTileAction } from 'vuetify/lib/components/VList';
import { VListTileContent } from 'vuetify/lib/components/VList';
import { VListTileTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
installComponents(component, {VIcon,VLayout,VList,VListTile,VListTileAction,VListTileContent,VListTileTitle,VNavigationDrawer})
