import verge from "verge";
export const state = () => ({
	user: ''
})


export const mutations = {
	

}

export const actions = {

	async refreshToken({commit, app}, response) {

		//let refreshToken = response.refresh;
		let result = await this.$axios.$post('/auth/refresh_token/', {'refresh': 'hej'})
		return result
	}
	
	

}