import moment from 'moment';

export default function ({ $axios, app, redirect }) {
    
    $axios.onRequest(config => {
      
    })
  
    $axios.onError(error => {
      const code = parseInt(error.response && error.response.status)
      if (code === 401) {
        redirect('/login')
      }
    })



  }