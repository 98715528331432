import Vue from 'vue'
import VueI18n from 'vue-i18n'
import VueCookie from 'vue-cookie'
Vue.use(VueCookie)


export default function ({ app }) {
    if (app.$auth && app.$auth.user) {
        //   store.dispatch('refreshAuth/initRefreshInterval')
    }

    app.i18n._vm.locale = 'da'

}