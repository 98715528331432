<template>
  <v-text-field
    outline
    :clear-icon="'close'"
    clearable
    :placeholder="'HH:MM'"
    @keyup="onChange"
    @blur="onChange"
    v-model="time"
  ></v-text-field>
</template>

<script>
export default {
  props: ["value", "currentTime"],
  data: function() {
    return {
      time: this.currentTime || this.value || ''
    };
  },
  watch: {
    currentTime() {
      if (this.value) {
        this.time = this.value;
      } else {
        this.time = this.currentTime;
      }
    },
    value() {
      this.time = this.currentTime || this.value || ''
    }
  },
  methods: {
    getTime() {
      return this.time;
    },
    onChange(e) {
      if (this.time.indexOf("::") > -1) this.time = "";
      if (this.time.length === 2) {
        this.time = this.time + ":";
        return;
      }
      if (this.time.length === 3 && this.time[3] == ":") {
        this.time = this.time[0] + this.time[1] + ":" + this.time[2];
      }
      if (this.time.length > 5) {
        this.time = this.time[0] + this.time[1] + ":";
      }
      this.$emit('input', this.time)
    }
  }
};
</script>

<style>
</style>