import verge from "verge";
export const state = () => ({
	leftDrawer: verge.viewportW() >= 1024,
	mobileDrawer: false,
})


export const mutations = {
	setMobileDrawer(state, mobileDrawer) {
		state.mobileDrawer = mobileDrawer;
	},

}