<template></template>
<script>
export default {
  data() {
    darkMode: false;
  },
  computed: {
    darkMode() {
      return this.$store.state.settings.isDark;
    },
    loggedIn() {
      return this.$store.state.auth.loggedIn;
    },
  }
};
</script>

