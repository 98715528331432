<template>
<v-switch :label="$t('Toggle dark theme')" v-model="isDark" color="primary"></v-switch>    
</template>
<script>
import ThemeMixin from "~/mixins/ThemeMixin.vue";
import EventBus from "~/libs/EventBus.js";
export default {
    extends: [ThemeMixin],
    data() {
        return {
            isDark: this.$store.state.settings.isDark
        }
    },
    watch: {
        isDark: function() {
            this.$store.commit('settings/setDarkMode', this.isDark)
            EventBus.$emit("darkMode", this.isDark)
            
        }
    }
}
</script>


