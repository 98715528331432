import EventBus from "~/libs/EventBus.js";
import moment from "moment";

let singleton;

const asyncForEach = async (array, callback) => {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
};

class WSController {
  constructor(domain, token, app) {
    this.token = token;
    this.$store = app.store;
    this.$app = app;

    this.api_domain = `${domain}/ws/`;

    this.connected = false;

    this.liveDataIds = {};

    this.joinedStables = {};

    this.disconnect_retries = 0;

    if (!singleton) {
      singleton = this;
      this.init();
    }

    return singleton;
  }
  init() {
    this.socket = new WebSocket(this.api_domain + "?token=" + this.token);

    this.socket.onmessage = this.onmessage.bind(this);
    this.socket.onopen = this.onconnect.bind(this);
    this.socket.onclose = (e) => {
      this.joinedStables = {};

      console.log(
        "Socket is closed. Reconnect will be attempted in 1 second.",
        e.reason
      );
      setTimeout(() => {
        this.init();
      }, 1000);
    };

    /* this.socket.on('disconnect', this.disconnect)


        
        this.socket.on('error', () => {
            this.disconnect()
        }); */
  }

  // XXX
  async isConnected() {
    let that = this;
    return new Promise((resolve) => {
      let checkConnectedInterval = setInterval(() => {
        if (that.connected) {
          clearInterval(checkConnectedInterval);
          resolve();
        }
      }, 500);
    });
  }

  addConsole(console) {
    this.console = console;
  }

  async sendMessage(msg) {
    let data = {
      token: this.token,
      message: msg,
    };
    if (this.connected) {
      this.socket.send(JSON.stringify(data));
    }
  }

  async getAlarms(stableId, data) {
    await asyncForEach(data.sections, async (s) => {
      let modData = Object.assign({}, data);
      modData.plc = s.section_id;
      modData.sections = null;
      this.sendCommand("parameter_get", stableId, modData);
      await new Promise((r) => {
        setTimeout(() => {
          r();
        }, 200);
      });
    });
  }

  sendCommand(command, stableId, commandData, should_log) {
    let data = {
      token: this.token,
      command: command,
      stableId: stableId,
      data: commandData,
      // Should log / create event
      should_log: should_log,
    };

    this.log(data, "default");
    this.sendMessage(JSON.stringify(data));
  }

  sendParameter(data, stableId, should_log = true) {
    this.sendCommand("parameter_set", stableId, data, should_log);
  }

  getAllParameters(stableId, data) {
    this.sendCommand("parameter_all", stableId, data);
  }

  log(message, logType = "default") {
    if (this.console) {
      this.console.log(message, logType);
    }
  }

  join(stableId) {
    if (this.joinedStables[stableId])
      return new Promise((resolve) => resolve());
    this.joinPromise = new Promise((resolve, reject) => {
      this.sendCommand("join", stableId);
      this.joinedStables[stableId] = {
        id: stableId,
        resolve: resolve,
        reject: reject,
      };
    });

    return this.joinPromise;
  }

  getLiveData(stableId, plcId, liveCount) {
    this.liveDataIds[`${stableId}_${plcId}`] = true;

    if (!liveCount) liveCount = 60 * 5;
    //if (!liveCount) liveCount = 5
    this.sendCommand("live", stableId, { plc: plcId, count: liveCount });
    if (!this.lastLive || moment().unix() > this.lastLive + 10 * 1000) {
      this.lastLive = moment().unix();
    }
    setTimeout(() => {
      this.liveDataIds[`${stableId}_${plcId}`] = false;

      EventBus.$emit("live_data_finished", {
        plcId,
        stableId,
      });
      this.getLiveData(stableId, plcId, liveCount);
    }, liveCount * 1000);
  }

  onmessage(msg) {
    let data = JSON.parse(msg.data);

    if (data.result === "join") {
      this.joinedStables[data.stableId].resolve();
    }

    if (data.type === "live" && data.keys) {
      this.$store.commit("stable/setLiveData", data);
    }

    if (data.command === "paragetall" && data.type === "cmdresponse") {
      this.$store.commit("stable/setParameterData", data);
      let locale = this.$app.i18n.locale
      this.$app.$toast.info(this.$app.i18n.messages[locale]["Parameters retrieved"] ? this.$app.i18n.messages[locale]["Parameters retrieved"] : "Parameters retrieved", {
        duration: 1500,
        containerClass: "toast-green",
      });
    }

    if (data.command === "paraget") {
      let stableId = data.args.gwid;
      EventBus.$emit(`alarm_${stableId}`, data);

      //this.$app.$toast.info('Alarms retrieved', {duration: 1000, containerClass: 'toast-green'})
    }

    this.log(data, "default");
  }
  onconnect() {
    this.connected = true;
  }

  getSocket() {
    return this.socket;
  }

  disconnect() {
    EventBus.$emit("socket_disconnected");
    this.socket.off("disconnect", this.disconnect);
    setTimeout(() => {
      this.socket = null;
      this.init();
    }, 1000);
  }
}

export { WSController };
