<template>
  <v-combobox
    class="mySearchBox"
    :dark="darkMode"
    v-model="model"
    :items="controllers"
    :loading="isLoading"
    :search-input.sync="search"
    hide-no-data
    hide-selected
    box
    item-text="name"
    item-value="name"
    label
    placeholder="Start typing to Search"
    prepend-icon="mdi-database-search"
    return-object
  >
    <template slot="item" slot-scope="data">
      <v-list-tile-content>
        <v-layout row justify-space-between full-width >
          <v-flex xs12 >
              {{data.item.name}} 
          </v-flex>
          
          
        </v-layout>
      </v-list-tile-content>
    </template>
  </v-combobox>
</template>
<script>
import ThemeMixin from "~/mixins/ThemeMixin.vue";

import EventBus from "~/libs/EventBus.js";
export default {
  props: ["controllers", 'isMobile'],
  extends: [ThemeMixin],
  data() {
    return {
      model: null,
      search: "name",
      isLoading: false,
      darkMode: false
    };
  },
  watch: {
    search() {
      this.$store.commit("filters/setSearch", this.search);
    },
    model: function(oldVal, newVal) {
      if (this.model != newVal) {
        if (typeof(this.model) === "string" ) {
          
          this.$store.commit("filters/setSearch", this.model);
          
        } else {
            
            this.$router.push("/controller/" + this.model.controller_id + "/live");
            this.$store.commit("filters/setSearch", '');
            
        }
        
        EventBus.$emit("SearchSelected", this.model);
      }
    }
  }
};
</script>
<style lang="stylus">
@import '~assets/colors.styl';

.mySearchBox.theme--dark.v-text-field--box > .v-input__control {
  min-height: 20px;
  margin-top: -10px;
  border: none;
}

.v-text-field.v-input--is-focused > .v-input__control > .v-input__slot:after {
  border-color: $p1Color !important;
  max-width: 95%;
  margin: 0 auto;
  margin-left: 2.5%;
}

.mySearchBox.theme--dark.v-text-field--box > .v-input__control > .v-input__slot {
  background: $innerColor !important;
  $searchBoxHeight ?= 35px;
  min-height: $searchBoxHeight !important;
  height: $searchBoxHeight !important;
  border-radius: 20px;
}

.theme--dark.v-text-field > .v-input__control > .v-input__slot:before {
  border: none;
}

.mySearchBox {
  height: 10px !important;

  .v-input__icon {
    display: none;
  }

  .v-card {
    background: $frameColor !important;
  }

  .v-text-field.v-input--is-focused > .v-input__control > .v-input__slot:after, .v-text-field > .v-input__control > .v-input__slot:before, .v-text-field > .v-input__control > .v-input__slot:before {
    border: none !important;
    display: none;
  }
}
</style>


