<template>
    <v-select
        :items="languages"
        v-model="selectedLanguage"
        label="Language"
    ></v-select>
</template>
<script>
export default {
    data: function() {
        return {
            selectedLanguage: '',
            languages: [
                {
                    text: 'English',
                    val: 'en',
                },
                {
                    text: 'Dansk',
                    val: 'da',
                }
            ]
        }
    },
    watch: {
        selectedLanguage() {
            this.$i18n.locale = this.selectedLanguage
            //this.$router.push(this.switchLocalePath(this.selectedLanguage))
        
        }
    }

}
</script>


