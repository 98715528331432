<template>
  <v-menu
    ref="menu1"
    v-model="menu1"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        :clearable="clearable"
        outline
        v-model="textDate"
        :label="label"
        hint="DD/MM/YYYY"
        persistent-hint
        append-icon="event"
        @blur="onBlur"
        v-on="on"
        v-on:keyup.enter="onBlur()"
      ></v-text-field>
    </template>
    <v-date-picker v-model="date" no-title @input="menu1 = false"></v-date-picker>
  </v-menu>
</template>
<script>
import moment from "moment";
export default {
  props: ["label", "value", "clearable"],
  data: function() {
    return {
      date: this.value ? this.value.format("YYYY-MM-DD") : null,
      menu1: false,
      textDate: this.value ? this.value.format("DD/MM/YYYY") : null
    };
  },
  methods: {
    getDate() {
      return this.date;
    },
    onBlur() {
      if (this.date) {
        this.date = this.parseDate(this.textDate);
        this.$emit("input", moment(this.date));
      }
    },
    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    }
  },
  watch: {
    date() {
      if (this.date) {
        this.textDate = moment(this.date).format("DD/MM/YYYY");
      }
    },
    textDate() {
      if (!this.textDate) {
        this.date = null;
      }
      this.$emit("input", moment(this.date));
    },
    value() {
      if (this.value.isValid()) {
        
        this.date = this.value.format("YYYY-MM-DD")
      }
      
    }
  },
  computed: {}
};
</script>
<style scoped lang="stylus"></style>


