<template>
  <v-app :dark="isDark">
    <MainHeader/>
    <SideMenu/>

    <v-content>
      <nuxt/>
      
    </v-content>

    <v-footer :fixed="true" app>
      <div class="text-xs-center" style="width: 100%">
        <span>&copy; {{ year }} - {{developer}}</span>
      </div>
    </v-footer>
  </v-app>
</template>
<script>
// Load vue 2 animate
require("vue2-animate/dist/vue2-animate.min.css");
import Vue from "vue";
import SideMenu from "~/components/layout/SideMenu.vue";
import MainHeader from "~/components/layout/MainHeader.vue";
import verge from "verge";
import WSController from "~/libs/WSController.js";

export default {
  
  data() {
    return {
      year: new Date().getFullYear(),
      developer: "Orbital A/S"
    };
  },
  mounted() {
    let ws = this.$getWSController();
  },

  watch: {
    loggedIn: function() {
      
    }
  },
  computed: {
    isDebug() {
      return this.$store.state.settings.debug;
    },
    isDark() {
      return this.$store.state.settings.isDark;
    }
  },
  components: {
    SideMenu,
    MainHeader,
    
  }
};
</script>
<style  >
.v-input--switch {
  padding-left: 20px;
  align-items: flex-end;
}
</style>


