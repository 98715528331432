import Vue from 'vue'
import moment from 'moment'
import EventBus from '~/libs/EventBus.js'



export const state = () => ({
    serviceLogs: [],
    instructionLogs: [],
    serviceLogFilter: {},
    instructionLogFilter: {},
})

export const mutations = {
	setServiceLogs(state, logs) {
        Vue.set(state, 'serviceLogs', logs.logs)
        state.serviceLogFilter = logs.options
        
    },
    setInstructionLogs(state, logs) {
        Vue.set(state, 'instructionLogs', logs.logs)
        state.instructionLogFilter = logs.options
    },
    clear(state) {
        Vue.set(state, 'serviceLogs', [])
        Vue.set(state, 'instructionLogs', [])

    }
    

}

export const actions = {

    async getServiceLogs({commit}, options) {
        
        let result = await this.$axios.$get(`/api/stables/${options.stable_id}/service_logs/`, {params: options})
        commit('setServiceLogs', {logs: result, options: options})
        return result
    },
    async getInstructionLogs({commit,state}, options) {
        if (!options) {
                options = state.instructionLogFilter;
        } else {
            options.is_instruction = 1
        }
        
        
        let result = await this.$axios.$get(`/api/stables/${options.stable_id}/service_logs/`, {params: options})
        commit('setInstructionLogs', {logs: result, options: options})
        return result
    },

    async exportLogs({commit}, options) {
        let result = await this.$axios.$get(`/api/stables/${options.stable_id}/service_logs/export/`, {params: options})
        return result
    }
	

}