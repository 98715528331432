import Vue from 'vue'
import moment from 'moment'
import EventBus from '~/libs/EventBus.js'
const consola = require('consola')


export const state = () => ({
	search: '',
	stables: [],
	currentStable: null,
	lastDataTimestamp: {},
})

export const mutations = {
	setStables(state, stables) {
		Vue.set(state, 'stables', stables)
	},
	setCurrentStable(state, stable) {
		if (!stable || !stable.sections) {
			Vue.set(state, 'currentStable', stable)
			return
		}


		let sections = stable.sections.filter(s => s).map(section => {
			if (!section.schema || !section.schema.keys) return
			

			let latest_log = section.latest_log
			let parameters = [];
			let areas = {}

			for (var keyName in section.schema.keys) {
				let param = section.schema.keys[keyName]
				if ( section.section_type === 'cleaner_special' && param.keyName && (param.scada_gfx_grp.toLowerCase().indexOf('base') || param.scada_gfx_grp.toLowerCase().indexOf('acid'))) {
					return;
				}

				if (latest_log && latest_log.data && latest_log.data.keys)  {
					let latest_value = latest_log.data.keys[keyName]
					try {
						//param.latest_value = latest_value[keyName]
					} catch (error) {
						//console.log(error, "Dont' worry")
					}
					param.latest_ts = moment(latest_log.created * 1000).format('DD/MM/YYYY HH:mm:ss')
				}

				if (param.acl === 'rw') {
					
					param.keyName = keyName
					param.name = param.keypretty || param.keyName
					if  (param.category.toLowerCase() === 'alarm' || param.gfx_type  === 'control') {
						
					} else {
						parameters.push(param)
					}
				}

				if (param.scada_gfx_grp) {
					param.keyName = keyName
					param.name = param.keypretty || param.keyName
					let area = areas[param.scada_gfx_grp]
					if (!area) {
						area = []
					}
					area.push(param)
					areas[param.scada_gfx_grp] = area
				} else {
					param.keyName = keyName
					param.name = param.keypretty || param.keyName
					let area = areas[param.scada_gfx_grp]
					if (!area) {
						area = []
					}
					area.push(param)
					areas[param.scada_gfx_grp] = area

				}
			}
			

			let areasProcessed = { "": [] }
			// sort areas
			for (var keyName in areas) {
				areas[keyName] = areas[keyName].sort((a, b) => a.index - b.index)
				let processed = {}
				areas[keyName].forEach(element => {
					let procssedId = element.keypretty || element.keyName
					element.keypretty = procssedId
					let item = processed[element.keypretty];
					let processedData = {}

					if (!item) {

						processedData = {
							keyName: element.keyName,
							keyNameB: element.keyName,
							name: element.keypretty.length ? element.keypretty : element.keyName,
							index: element.index,
							siunit: element.siunit,
							scale: element.scale,
							max: element.max,

							keypretty_danish: element.keypretty_danish,
							keypretty: element.keypretty,
							link_keypretty: element.link_keypretty,
							
							parent_keypretty: element.parent_keypretty,
							default: element.default,
							min: element.min,
							type: element.type,
							gfx_type: element.gfx_type,
							tooltip: 'Set point set to',

							a: {
								status: 'ok',
								value: element.latest_value,
								unit: element.siunit
							},
							b: {
								status: 'ok',
								value: element.latest_value,
								unit: element.siunit
							}
						}

						if (keyName == 'alarm') {
							
							processedData.statekey = element.statekey
							processedData.section = element.section
							processedData.dataType = element.type
						}

						processed[element.keypretty] = processedData
					} else {
						
						if (element.type === 'setpoint') {
							
							toolTipParentProcessedData = processed[element.parent_keypretty]
							toolTipParentProcessedData = '';	
						}
						if (keyName == 'alarm') {
							
							processedData.statekey = element.statekey
							processedData.section = element.section
							processedData.dataType = element.type
						}
						processedData = processed[element.keypretty]
						processedData.keyNameB = element.keyName 
						processedData.b.value = element.latest_value

					}
				});
				

				areasProcessed[keyName] = Object.keys(processed).map(key => {
					let element = processed[key];
					
					
					if (element.parent_keypretty) {
						
						
						Object.keys(processed).map(key => {
							let elm = processed[key]
							
							
							if (elm.link_keypretty == element.parent_keypretty) {
								
								elm.a.setpoint = element.a.value
								elm.b.setpoint = element.b.value
								
								
								
								
							}
						})

					}

					return element
				})

				// Fix setpoints



			}


			section.parameters = parameters;
			section.areas = areas;

			section.areasProcessed = areasProcessed;



			return section
		})


		stable.sections = sections

		Vue.set(state, 'currentStable', stable)
	},
	setLiveData(state, data) {
		let stable = state.currentStable;
		
		// User on overview page and no current stable set
		if(!stable) {
			return
		}
		
		
		Vue.set(state, 'currentStable', stable)
		
		// Discard data from other plcs
		if (data.gwid !== stable.id) return
		
		EventBus.$emit(`stable_live_${stable.id}`)
		
		


		if (!stable) return
		let section = stable.sections.filter(section => section.section_id === data.plcid)[0]

		if (!section) return


		

		Object.keys(data.keys).forEach(category => {
			if (!section.areasProcessed[category]) return;

			let newArea = Object.keys(section.areasProcessed[category]).map(key => {
				let currentVal = section.areasProcessed[category][key]

				// Data object from live
				let entryA = data.keys[category][currentVal.keyName]
				let entryB = data.keys[category][currentVal.keyNameB]
		
				currentVal.a.value = entryA ? entryA.value : ''
				currentVal.b.value = entryB ? entryB.value: ''
				
				return currentVal
			})
			
			// XXX: Performance optimize
			section.areasProcessed[category] = Object.keys	(newArea).map(key => {
				let element = newArea[key];
				if (element.parent_keypretty) {
					
					Object.keys(newArea).map(key => {
						let elm = newArea[key]
						if (elm.link_keypretty == element.parent_keypretty) {
							if (element.keyName.indexOf('g_pv_uiRHVentilationSetpoint') > -1) {
								//console.info(elm, element, "SETPOINT")								
							}
							
							
							elm.a.setpoint = element.a.value 
							elm.b.setpoint = element.b.value 

							if (!elm.a.setpoint) {
								
								try {
									elm.a.setpoint = data.keys[""][element.keyName].value	
								} catch (error) {
									
								}
								
							}
							
							
						}
					})

				}

				return element
			})

			// Fix setpoints

		})
		
		EventBus.$emit('liveData', {stable: stable})

	},

	setParameterData(state, data) {


		let parameters = [];

		let section = state.currentStable.sections.find(s => s.section_id === data.args.plc)

		for (var keyName in data.return.result) {
			let param = section.schema.keys[keyName]
			if (!param) continue;
			if (param.acl === 'rw') {
				//param.keyName = keyName
				//param.name = param.keypretty || param.keyName
				param.latest_value = data.return.result[keyName]
				param.sectionId = section.section_id
				if  (param.category.toLowerCase() === 'alarm' || param.gfx_type  === 'control') {
					
				} else {
					parameters.push(param)
				}
			}
		}
		section.parameters = parameters;
		state.currentStable.sections[section.section_id] = section
		Vue.set(state, 'currentStable', state.currentStable)
	}
}

export const actions = {
	async getStables({ commit }, options) {
		let stables = await this.$axios.$get(`/api/stables/`)
		commit('setStables', stables)
		return stables
	},
	async getStable({ commit }, id) {
		let stable = await this.$axios.$get(`/api/stables/${id}/`)
		commit('setCurrentStable', stable)
	},

}

export const strict = false