import Vue from 'vue';
const EventBus = new Vue();


EventBus.$on('login', () => {
        
})

EventBus.$on('graphChange', (e) => {
    
})

export default EventBus