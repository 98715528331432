import moment from 'moment';
import EventBus from '~/libs/EventBus.js'

export default function ({ $axios, app, redirect }) {

    // Check for expiration
    if (app.store.state.admin.loginTime) {
        let beenWarned = false;
        let checkTokenExpiration = () => {
            let lastLoginTime = moment(app.store.state.admin.loginTime)
            let now = moment()
            if (app.store.state.admin.loginTime && now.diff(lastLoginTime, 'minutes') > 1 && !beenWarned) {
                EventBus.$emit('loginWillExpireSoon')
                beenWarned = true;
            }
            
        }
        checkTokenExpiration()
        let checkLoginExpirationInterval = setInterval(checkTokenExpiration, 1000 * 10);
    }


}