import Vue from 'vue'
export const state = () => ({
    stableEvents: {

    }
})

export const mutations = {

    setEvents(state, data) {
        let stableEvents = Object.assign({}, state.stableEvents)
        stableEvents[data.stable] = data.events
        Vue.set(state, 'stableEvents', stableEvents )
    }
}

export const actions = {
    async getEvents({commit}, options) {
        let events = await this.$axios.$post(`/api/stables/${options.stable}/events/`, options)
        commit('setEvents', {
            stable: options.stable,
            events: events
        })
    },

    async getActiveAlarms({commit}, options) {
        let active_alarms = await this.$axios.$get(`/api/stables/${options.stable}/active_alarms/`)
        return active_alarms
        
    },

    async acknowledgeError({commit}, data) {
        let event = await this.$axios.$post(`/api/stables/${data.stable}/acknowledge_error/`, data)
        return event
    }
};
