import Vue from 'vue';
import moment from 'moment';

export const state = () => ({
    users: [],
    selectedUser: null,
    originalToken: null,
    currentStableUsers: [],
    loginTime: null


})

export const mutations = {

    setCurrentStableUsers(state, users) {
        state.currentStableUsers = users;
    },



    setUsers(state, users) {
        state.users = users;
    },
    setSelectedUser(state, user) {
        state.selectedUser = user;
    },
    setOriginalToken(state, originalToken) {
        state.originalToken = originalToken;
    },
    setLoginTime(state, loginTime) {
        state.loginTime = loginTime
    }

}

export const actions = {

    async getStableUsers({ commit, dispatch }, stableId) {
        let res = await this.$axios.$get(`/api/stables/` + stableId + '/users/')
        commit('setCurrentStableUsers', res)
        return res
    },

    async addStableUser({ commit, dispatch }, options) {
        let res = await this.$axios.$post(`/api/stables/` + options.stableId + '/users/', options)
        return res
    },

    async removeStableUser({ commit, dispatch }, data) {
        let res = await this.$axios.$post(`/api/stables/` + data.stableId + '/users/', data)
        return res
    },

    // Deletes a user entirely
    async deleteStableUser({ commit, dispatch }, data) {
        let res = await this.$axios.$post(`/api/stables/` + data.stableId + '/users/', data)
        return res
    },

    async sendInvitationToStableUser({ commit, dispatch }, stableId, data) {
        let res = await this.$axios.$post(`/api/stables/` + stableId + '/users/', data)
        return res
    },


    async getUsers({ commit, dispatch }) {
        let res = await this.$axios.$get(`/api/users/registered_users/`)
        commit('setUsers', res)
        return res
    },

    async getUser({ commit, dispatch }, userID) {
        let res = await this.$axios.$get(`/api/users/${userID}/`)
        commit('setSelectedUser', res)
        return res
    },

    async loginAsUser({ commit }, options) {
        if (state.originalToken) {
      //      alert("Alr")
        };
        commit('setOriginalToken', options.originalToken)
        let res = await this.$axios.$post(`/api/loginas/`, options)
        
        return res;
    },


}