import verge from "verge";
export const state = () => ({
	search: ''
})


export const mutations = {
	setSearch(state, search) {
		state.search = search;
	},

}