import { render, staticRenderFns } from "./MainHeader.vue?vue&type=template&id=66a387bc&scoped=true&"
import script from "./MainHeader.vue?vue&type=script&lang=js&"
export * from "./MainHeader.vue?vue&type=script&lang=js&"
import style0 from "./MainHeader.vue?vue&type=style&index=0&id=66a387bc&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66a387bc",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListTile } from 'vuetify/lib/components/VList';
import { VListTileTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarSideIcon } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VAvatar,VIcon,VList,VListTile,VListTileTitle,VMenu,VSpacer,VToolbar,VToolbarSideIcon,VToolbarTitle})
