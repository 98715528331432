import verge from "verge";
export const state = () => ({
	isDark: true,
	debug: 0,
	defaultMap: 'dark',
	leftDrawer: verge.viewportW() >= 1024,
	mobileDrawer: false,
})


export const mutations = {
	setDebug(state, debug) {
		state.debug = debug;
	},
	setDarkMode(state, isDark) {
		state.isDark = isDark;
	},
	setDefaultMap(state,map) {
		state.defaultMap = map;
	},
	setMobileDrawer(state, mobileDrawer) {
		state.mobileDrawer = mobileDrawer;
	},

}