<template>
  <v-list-tile>
    <v-list-tile-action>
      <v-icon>perm_identity</v-icon>
    </v-list-tile-action>
    <v-list-tile-content>
      <p>{{user.name}}</p>
    </v-list-tile-content>
  </v-list-tile>
</template>
<script>
export default {
  data() {
    return {};
  },
  computed: {
    user() {
      return this.$store.state.auth.user;
    }
  }
};
</script>

<style lang="stylus" scoped>
p {
  font-size: 14px;
  margin: 0;
}
</style>



