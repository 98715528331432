<template>
  <div id="MainSideMenu">
    <!-- For DESKTOP-->
    <v-navigation-drawer
      :width="230"
      class="hidden-md-and-down left-drawer"
      :mini-variant="false"
      :clipped="false"
      v-model="leftDrawer"
      persistent
      fixed
      enable-resizer-watcher
      app
    >
      <v-layout row wrap align-content-space-between fill-height>
        <v-list>
          <div v-for="(item, i) in links" :key="i">
            <v-list-tile
              :to="item.to"
              :href="item.href"
              router
              exact
              :disabled="item.disabled"
              v-if="
            (item.login && loggedIn) ||
              (!item.login && item.login_hidden && !loggedIn)"
              
            >
              <v-list-tile-action>
                <v-icon v-if="!item.isSvg" v-html="item.icon" />

                <img v-else :src="item.icon" width="23" style="margin-top: 1px;" alt />
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title :class="{'timeout': item.has_timeout}" v-text="item.title" color="primary" />
              </v-list-tile-content>
            </v-list-tile>

            <v-list-tile avatar v-if="originalToken" @click="revertToOriginalUser()">
              <v-list-tile-action>
                <v-icon>logout</v-icon>
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title>Original user</v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
          </div>
        </v-list>

        <v-layout row wrap justify-end style="display: none;">
          <ThemeToggle />
        </v-layout>
      </v-layout>
    </v-navigation-drawer>

    <!-- For MOBILE-->
    <v-navigation-drawer
      class="hidden-lg-and-up"
      :mini-variant="miniVariant"
      disable-resize-watcher
      :clipped="clipped"
      touchless
      v-model="mobileDrawer"
      fixed
      app
    >
      <v-list>
        <div v-for="(item, i) in links" :key="i">
          <v-list-tile
            :to="item.to"
            :href="item.href"
            router
            exact
            v-if="
            (item.login && loggedIn) ||
              (!item.login && item.login_hidden && !loggedIn)
          "
            
          >
            <v-list-tile-action>
              <v-icon v-if="!item.isSvg" v-html="item.icon" />

              <img v-else :src="item.icon" width="23" style="margin-top: 1px;" alt />
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title :class="{'timeout': item.has_timeout}" v-text="item.title" color="primary" />
            </v-list-tile-content>
          </v-list-tile>
        </div>

        <v-list-tile avatar v-if="originalToken" @click="revertToOriginalUser()">
          <v-list-tile-action>
            <v-icon>logout</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>Original user</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
      </v-list>

      <v-layout row wrap align-content-end>
        <LoggedInUser />
      </v-layout>
    </v-navigation-drawer>
  </div>
</template>
<script>
import SearchBox from "~/components/layout/SearchBox.vue";
import { mapState, mapGetters } from "vuex";
import { getAppMenuItems, getBarnMenuItems } from "~/libs/AppHelper.js";
import ThemeMixin from "~/mixins/ThemeMixin.vue";
import ThemeToggle from "~/components/misc/ThemeToggle.vue";
import LoggedInUser from "~/components/misc/LoggedInUser.vue";

export default {
  props: ["controllers"],
  extends: [ThemeMixin],
  data: function() {
    return {
      links: this.showBarnMenu
        ? getBarnMenuItems.apply(this)
        : getAppMenuItems.apply(this),
      darkMode: this.darkMode,
      miniVariant: false,
      clipped: false
    };
  },
  mounted() {
    // Hack for direct links
    this.links = this.showBarnMenu
      ? getBarnMenuItems.apply(this)
      : getAppMenuItems.apply(this);

    console.log(this.$i18n);
    this.$i18n.setLocaleCookie("da");
    this.$cookie.set("locale", "da-DK");

    //this.$root.$i18n.locale = 'da'
  },
  computed: {
    mobileDrawer: {
      get() {
        return this.$store.state.layout.mobileDrawer;
      },
      set(val) {
        this.$store.commit("layout/setMobileDrawer", val);
      }
    },
    leftDrawer: {
      get() {
        return this.$store.state.layout.leftDrawer;
      },
      set(val) {
        //this.$store.commit("layout/setMobileDrawer", val);
      }
    },
    originalToken() {
      return this.$store.state.admin.originalToken;
    },
    page() {
      return this.$refs.page;
    },
    showBarnMenu() {
      return this.$route.path.indexOf("stable") > -1;
    },
    currentStable() {
      return this.$store.state.stable.currentStable;
    }
  },
  watch: {
    $route() {
      this.updateLinks();
    },
    currentStable() {
      this.updateLinks();
    }
  },
  components: {
    ThemeToggle,
    LoggedInUser,
    SearchBox
  },
  methods: {
    loggedIn() {
      return this.$store.state.auth.loggedIn;
    },
    updateLinks() {
      if (this.showBarnMenu) {
        this.links = getBarnMenuItems.apply(this);
      } else {
        this.links = getAppMenuItems.apply(this);
      }
    },
    revertToOriginalUser() {
      this.$auth.setToken("local", this.originalToken);
      this.$axios.setToken(this.originalToken);
      this.$store.dispatch("api/getControllers").then(() => {
        this.$store.dispatch("wsstore/doWSRegistration");
      });

      this.$store.commit("admin/setOriginalToken", null);
    }
  }
};
</script>
<style lang="stylus" scoped>
.search {
  margin: 40px 0;
}

.v-list__tile__title.timeout {
  color: yellow;
}
</style>


